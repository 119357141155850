<template>
  <div class="cookie-policy pt-4">
    <h5 class="letter-spacing-2">COOKIE POLICY</h5>

    <div class="container">
      <div class="row text-start">
        <div class="col-8 offset-2 ">
          <p>
            <strong>This is the Cookie Policy for Easetate</strong>, accessible from
            <a class="text-decoration-none" href="https://www.easetate.co.uk">
              www.easetate.co.uk
            </a>
          </p>

          <p>Click on the links below to go straight to more information on each area:</p>

          <div>
            <ul>
              <li><a href="#what_are_cookies" class="link-info">What Are Cookies</a></li>
              <li><a href="#how_we_use_cookies" class="link-info">How We Use Cookies</a></li>
              <li><a href="#disabling_cookies" class="link-info">Disabling Cookies</a></li>
              <li><a href="#cookies_we_set" class="link-info">The Cookies We Set</a></li>
              <li><a href="#third_party_cookies" class="link-info">Third Party Cookies</a></li>
              <li><a href="#more_info" class="link-info">More Information</a></li>
            </ul>
          </div>

          <div>
            <ol>
              <!-- 1 -->
              <li id="what_are_cookies"><strong>WHAT ARE COOKIES</strong></li>
              <ol type="I">
                <li>
                  As is common practice with almost all professional websites this site uses cookies,
                  which are tiny files that are downloaded to your computer, to improve your experience.
                  This page describes what information they gather, how we use it and why we sometimes need to
                  store these cookies. We will also share how you can prevent these cookies from being stored
                  however this may downgrade or 'break' certain elements of the sites functionality.
                </li>
                <li>
                  Local Storage
                  In addition to cookies, local storage allows us to use a tiny bit of space on your machine to store
                  information, in much the same way as cookies. We use this to store information which, we feel,
                  enhances your journey through the site by remembering previous actions and making them default
                  for the future. All local storage is persistent, with no expiry.
                </li>
              </ol>
              <!-- 2 -->
              <li id="how_we_use_cookies"><strong>HOW WE USE COOKIES</strong></li>
              <ol type="I">
                <li>
                  We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no
                  industry standard options for disabling cookies without completely disabling the functionality and
                  features they add to this site. It is recommended that you leave on all cookies if you are not sure
                  whether you need them or not in case they are used to provide a service that you use.
                </li>
              </ol>
              <!-- 3 -->
              <li id="disabling_cookies"><strong>DISABLING COOKIES</strong></li>
              <ol type="I">
                <li>
                  You can prevent the setting of cookies by adjusting the settings on your browser
                  (see your browser Help for how to do this). Be aware that disabling cookies will affect the
                  functionality of this and many other websites that you visit. Disabling cookies will usually
                  result in also disabling certain functionality and features of the site. Therefore it is
                  recommended that you do not disable cookies.
                </li>
              </ol>
              <!-- 4 -->
              <li id="cookies_we_set"><strong>THE COOKIES WE SET</strong></li>
              <ol type="I">
                <li>
                  <p>Account related cookies</p>
                  <p>If you create an account with us then we will use cookies for the management of the
                    signup process and general administration. These cookies will usually be deleted when
                    you log out however in some cases they may remain afterwards to remember your site preferences
                    when logged out.</p>
                </li>

                <li>
                  <p>Login related cookies</p>
                  <p>We use cookies when you are logged in so that we can remember this fact. This prevents
                    you from having to log in every single time you visit a new page. These cookies are typically
                    removed or cleared when you log out to ensure that you can only access restricted features
                    and areas when logged in.</p>
                </li>

                <li>
                  <p>Forms related cookies</p>
                  <p>When you submit data to through a form such as those found on premises search filters
                    cookies may be set to remember your user details for future correspondence.</p>
                </li>

                <li>
                  <p>Site preferences cookies</p>
                  <p>In order to provide you with a great experience on this site we provide the functionality
                    to set your preferences for how this site runs when you use it. In order to remember your
                    preferences we need to set cookies so that this information can be called whenever you interact
                    with a page is affected by your preferences.</p>
                </li>
              </ol>
              <!-- 5 -->
              <li id="third_party_cookies"><strong>THIRD PARTY COOKIES</strong></li>
              <ol type="I">
                  <li>In some special cases we also use cookies provided by trusted third parties.
                    The following section details which third party cookies you might encounter
                    through this site.</li>

                  <li>
                    <p>This site uses Google Analytics which is one of the most widespread and trusted analytics
                      solution on the web for helping us to understand how you use the site and ways that we can
                      improve your experience. These cookies may track things such as how long you spend on the
                      site and the pages that you visit so we can continue to produce engaging content.</p>
                    <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
                  </li>

                  <li>
                    <p>Third party analytics are used to track and measure usage of this site so that we can continue
                      to produce engaging content. These cookies may track things such as how long you spend on
                      the site or pages you visit which helps us to understand how we can improve the site for you.</p>
                  </li>

                  <li>
                    <p>From time to time we test new features and make subtle changes to the way that the site
                      is delivered. When we are still testing new features these cookies may be used to ensure
                      that you receive a consistent experience whilst on the site whilst ensuring we understand
                      which optimisations our users appreciate the most.</p>
                  </li>

                  <li>
                    <p>As we sell products it's important for us to understand statistics about how many of the
                      visitors to our site actually make a purchase and as such this is the kind of data that
                      these cookies will track. This is important to you as it means that we can accurately
                      make business predictions that allow us to monitor our advertising and product costs to
                      ensure the best possible price.</p>
                  </li>

                  <li>
                    <p>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to
                      serve more relevant ads across the web and limit the number of times that a given ad is shown to you.</p>
                    <p>For more information on Google AdSense see the official Google AdSense privacy FAQ.</p>
                  </li>

                  <li>
                    <p>We use adverts to offset the costs of running this site and provide funding for
                      further development. The behavioural advertising cookies used by this site are designed
                      to ensure that we provide you with the most relevant adverts where possible by anonymously
                      tracking your interests and presenting similar things that may be of interest.</p>
                  </li>
              </ol>
              <!-- 6 -->
              <li id="more_info"><strong>MORE INFORMATION</strong></li>
              <ol type="I">
                <li>Hopefully that has clarified things for you and as was previously mentioned if there is something
                  that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it
                  does interact with one of the features you use on our site.</li>

                <li>However if you are still looking for more information then you can contact us through one of our
                  preferred contact methods:</li>

                <ul>
                  <li>
                    Email: <a class="text-decoration-none" href="mailto:support@easetate.co.uk">
                      <font-awesome-icon icon="envelope" /> support@easetate.co.uk
                    </a>
                  </li>
                  <li>
                    By visiting this link: <router-link class="text-decoration-none" to="/contact">contact us</router-link>
                  </li>
                </ul>
              </ol>
            </ol>
            <p>Thank you for visiting Easetate.</p>
          </div>
          <p><strong>Updated in:</strong> May 2022</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicy'
}
</script>

<style lang="scss">
.cookie-policy {
  ul, ol {
    padding: 6px;
    li {
      padding: 3px;
    }
  }
}
</style>